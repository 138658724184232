import { Component, OnInit } from '@angular/core';
import { AuthService } from './core/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  
  title = 'frontend';
  islogin:boolean =false;
  name:any="" 
  index:any=""
  user:any=null
  constructor(
    public authSrv:AuthService
  ) {}

  ngOnInit(): void {
     this.authSrv.isLoggedIn.subscribe(rep=>{
      this.islogin = rep;
      
    })

     this.authSrv.authUser.subscribe(rep=>{
       console.log("Auth user")
       console.log(rep)
       if(rep){
        this.user =  rep
        this.name = this.user.displayName;
        this.index = this.user.index;
       }
       
    })

  }
}
