<div style="height: 100vh;" fxLayout="column">
    <mat-toolbar fxFlex="64px" fixed class="mat-elevation-z3">
        <img width="120px" style="margin-right: 10px;" src="./assets/edumix.png" alt="" srcset="">
    </mat-toolbar>
    <div class="outer-container">
        <router-outlet></router-outlet>
    </div>
    <div class="footer mat-elevation-z1" fxLayout="row">
    <div fxFlex fxHide.lt-sm="true"></div>
    <div fxFlex class="copyright">© 2021 Edumix. All rights reserved.</div>
    <div fxFlex fxHide.lt-sm="true"></div>
    </div>
</div>
