import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'quiz',
    loadChildren: () => import('./quiz/quiz.module').then((m) => m.QuizModule),
   // canActivate: [AuthGuard]
  },
  {
    path: 'quiz-new',
    loadChildren: () => import('./quiz-new/quiz-new.module').then((m) => m.QuizNewModule),
   // canActivate: [AuthGuard]
  },
  {
    path: 'quiz-essay',
    loadChildren: () => import('./quiz-essay/quiz-essay.module').then((m) => m.QuizEssayModule),
   // canActivate: [AuthGuard]
  },
  {
    path: 'exam-result',
    loadChildren: () => import('./exam-result/exam-result.module').then((m) => m.ExamResultModule),
   // canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '', redirectTo: 'quiz', pathMatch: 'full' },
  { path: '**', redirectTo: 'quiz' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
