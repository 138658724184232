import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthUser } from '../models/auth-user';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authUser: BehaviorSubject<AuthUser> = new BehaviorSubject(null);
  settings: BehaviorSubject<Boolean> = new BehaviorSubject(null);

  constructor(private http: HttpClient) { 
    if(environment.persistUser){
      const local = localStorage.getItem('__dpusr');
      try{
        this.setuser(JSON.parse(local));
      }catch(e){}
    }
  }

  authenticate(jwt: string): Observable<AuthUser> {
    var formData = new FormData();
    formData.append('jwt',jwt);
    return this.http.post<AuthUser>(`${environment.apiUrl}auth/token`, formData);
  }

  basicAuth(data): Observable<AuthUser> {
    return this.http.post<AuthUser>(`${environment.apiUrl}auth/basic`, data);
  }

  setuser(user: AuthUser): void{
    if (user && user.jwt){
      this.authUser.next(user);
      this.isLoggedIn.next(true);
      localStorage.setItem('__dpusr', JSON.stringify(user));
    }
  }
}
